import Plyr from "plyr";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      plyr: (el, options) => {
        return new Plyr(el, options);
      },
    },
  };
});
