const clearLocalStorage = () => {
  localStorage.clear();
};

const clearSessionStorage = () => {
  sessionStorage.clear();
};

const clearAll = () => {
  clearLocalStorage();
  clearSessionStorage();
};

export default defineNuxtPlugin(() => {
  window.clearSession = clearAll;
  return {
    provide: {
      clearSession: clearAll,
    },
  };
});
