import { defineStore } from "pinia";

export const useKonamiStore = defineStore("konami", {
  state: () => ({
    code: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    index: 0,
    activated: false,
  }),
  getters: {},
  actions: {
    onKeyUp(event) {
      if (this.activated) return;

      if (event.keyCode === this.code[this.index]) {
        this.index++;
        if (this.index === this.code.length) {
          this.activated = true;
        }
      } else {
        this.index = 0;
      }
    },
  },
});
