import revive_payload_client_4sVQNw7RlN from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/nficano/github/teachmehipaa/course/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/nficano/github/teachmehipaa/course/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/Users/nficano/github/teachmehipaa/course/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/Users/nficano/github/teachmehipaa/course/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/nficano/github/teachmehipaa/course/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/Users/nficano/github/teachmehipaa/course/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import bus_client_VwprazHOAT from "/Users/nficano/github/teachmehipaa/course/plugins/bus.client.js";
import confetti_client_TavMX3PFc6 from "/Users/nficano/github/teachmehipaa/course/plugins/confetti.client.js";
import firebase_client_zXNDa0wxFH from "/Users/nficano/github/teachmehipaa/course/plugins/firebase.client.ts";
import lottie_client_funiu1oBjt from "/Users/nficano/github/teachmehipaa/course/plugins/lottie.client.js";
import piniaPersistedState_client_9nw3mdY0Mq from "/Users/nficano/github/teachmehipaa/course/plugins/piniaPersistedState.client.js";
import plyr_client_QNniPXDZgF from "/Users/nficano/github/teachmehipaa/course/plugins/plyr.client.js";
import session_client_o1l2wpndaC from "/Users/nficano/github/teachmehipaa/course/plugins/session.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_tbFNToZNim,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  bus_client_VwprazHOAT,
  confetti_client_TavMX3PFc6,
  firebase_client_zXNDa0wxFH,
  lottie_client_funiu1oBjt,
  piniaPersistedState_client_9nw3mdY0Mq,
  plyr_client_QNniPXDZgF,
  session_client_o1l2wpndaC
]