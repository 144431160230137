import { nanoid } from "nanoid";
import Sqids from "sqids";
const sqids = new Sqids();

export const createUid = () => {
  return nanoid(10);
};

export const createSqid = (uid) => {
  const id = sqids.encode([uid, 9, 9, 9]);
  return id;
};

export const decodeSqId = (uid) => {
  const id = sqids.decode(uid)[0];
  return id;
};

export const createToken = (ids) => {
  return btoa(ids.join(":"))
    .match(/.{1,5}/g)
    ?.join("-");
};

export const decodeToken = (token) => {
  return atob(token.replace(/-/g, "")).split(":");
};
