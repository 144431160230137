<template>
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>

<script setup>
import questions from "~/assets/documents/questions.json";

useHead({
  bodyAttrs: {
    class: "h-full",
  },
  htmlAttrs: {
    class: "h-full",
  },
});

const konami = useKonamiStore();
const assessment = useAssessmentStore();

konami.$subscribe((_, state) => {
  if (state.activated) {
    Object.keys(assessment.questions).map((i) => {
      return (assessment.questions[i].selected = questions[i].answer);
    });
    const router = useRouter();
    router.push("/assessment/review");
  }
});

onMounted(() => window.addEventListener("keyup", (e) => konami.onKeyUp(e)));
onUnmounted(() => window.removeEventListener("keyup", (e) => konami.onKeyUp(e)));
</script>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
