import { default as _91id_93Q1rrzksWvvMeta } from "/Users/nficano/github/teachmehipaa/course/pages/[assessment]/[id].vue?macro=true";
import { default as indexdQZ1bECr4tMeta } from "/Users/nficano/github/teachmehipaa/course/pages/[assessment]/index.vue?macro=true";
import { default as reviewonTjmTDOgHMeta } from "/Users/nficano/github/teachmehipaa/course/pages/[assessment]/review.vue?macro=true";
import { default as video_45_91id_939d0ijh7Id2Meta } from "/Users/nficano/github/teachmehipaa/course/pages/[lession]/video-[id].vue?macro=true";
import { default as certificateNJIVBdbBLiMeta } from "/Users/nficano/github/teachmehipaa/course/pages/certificate.vue?macro=true";
import { default as entryHiDcShvZHXMeta } from "/Users/nficano/github/teachmehipaa/course/pages/entry.vue?macro=true";
import { default as helloiaVo3waucRMeta } from "/Users/nficano/github/teachmehipaa/course/pages/hello.vue?macro=true";
import { default as indexcTCDDgJX2WMeta } from "/Users/nficano/github/teachmehipaa/course/pages/index.vue?macro=true";
import { default as _91token_93xM7s4ndFLuMeta } from "/Users/nficano/github/teachmehipaa/course/pages/join/[token].vue?macro=true";
import { default as indexNK5khdkQwYMeta } from "/Users/nficano/github/teachmehipaa/course/pages/join/index.vue?macro=true";
import { default as signoutto5R9PR3CaMeta } from "/Users/nficano/github/teachmehipaa/course/pages/signout.vue?macro=true";
import { default as unauthorizedA1MdIRuviEMeta } from "/Users/nficano/github/teachmehipaa/course/pages/unauthorized.vue?macro=true";
export default [
  {
    name: _91id_93Q1rrzksWvvMeta?.name ?? "assessment-id",
    path: _91id_93Q1rrzksWvvMeta?.path ?? "/:assessment()/:id()",
    meta: _91id_93Q1rrzksWvvMeta || {},
    alias: _91id_93Q1rrzksWvvMeta?.alias || [],
    redirect: _91id_93Q1rrzksWvvMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/[assessment]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdQZ1bECr4tMeta?.name ?? "assessment",
    path: indexdQZ1bECr4tMeta?.path ?? "/:assessment()",
    meta: indexdQZ1bECr4tMeta || {},
    alias: indexdQZ1bECr4tMeta?.alias || [],
    redirect: indexdQZ1bECr4tMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/[assessment]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewonTjmTDOgHMeta?.name ?? "assessment-review",
    path: reviewonTjmTDOgHMeta?.path ?? "/:assessment()/review",
    meta: reviewonTjmTDOgHMeta || {},
    alias: reviewonTjmTDOgHMeta?.alias || [],
    redirect: reviewonTjmTDOgHMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/[assessment]/review.vue").then(m => m.default || m)
  },
  {
    name: video_45_91id_939d0ijh7Id2Meta?.name ?? "lession-video-id",
    path: video_45_91id_939d0ijh7Id2Meta?.path ?? "/:lession()/video-:id()",
    meta: video_45_91id_939d0ijh7Id2Meta || {},
    alias: video_45_91id_939d0ijh7Id2Meta?.alias || [],
    redirect: video_45_91id_939d0ijh7Id2Meta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/[lession]/video-[id].vue").then(m => m.default || m)
  },
  {
    name: certificateNJIVBdbBLiMeta?.name ?? "certificate",
    path: certificateNJIVBdbBLiMeta?.path ?? "/certificate",
    meta: certificateNJIVBdbBLiMeta || {},
    alias: certificateNJIVBdbBLiMeta?.alias || [],
    redirect: certificateNJIVBdbBLiMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/certificate.vue").then(m => m.default || m)
  },
  {
    name: entryHiDcShvZHXMeta?.name ?? "entry",
    path: entryHiDcShvZHXMeta?.path ?? "/entry",
    meta: entryHiDcShvZHXMeta || {},
    alias: entryHiDcShvZHXMeta?.alias || [],
    redirect: entryHiDcShvZHXMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/entry.vue").then(m => m.default || m)
  },
  {
    name: helloiaVo3waucRMeta?.name ?? "hello",
    path: helloiaVo3waucRMeta?.path ?? "/hello",
    meta: helloiaVo3waucRMeta || {},
    alias: helloiaVo3waucRMeta?.alias || [],
    redirect: helloiaVo3waucRMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/hello.vue").then(m => m.default || m)
  },
  {
    name: indexcTCDDgJX2WMeta?.name ?? "index",
    path: indexcTCDDgJX2WMeta?.path ?? "/",
    meta: indexcTCDDgJX2WMeta || {},
    alias: indexcTCDDgJX2WMeta?.alias || [],
    redirect: indexcTCDDgJX2WMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93xM7s4ndFLuMeta?.name ?? "join-token",
    path: _91token_93xM7s4ndFLuMeta?.path ?? "/join/:token()",
    meta: _91token_93xM7s4ndFLuMeta || {},
    alias: _91token_93xM7s4ndFLuMeta?.alias || [],
    redirect: _91token_93xM7s4ndFLuMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/join/[token].vue").then(m => m.default || m)
  },
  {
    name: indexNK5khdkQwYMeta?.name ?? "join",
    path: indexNK5khdkQwYMeta?.path ?? "/join",
    meta: indexNK5khdkQwYMeta || {},
    alias: indexNK5khdkQwYMeta?.alias || [],
    redirect: indexNK5khdkQwYMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/join/index.vue").then(m => m.default || m)
  },
  {
    name: signoutto5R9PR3CaMeta?.name ?? "signout",
    path: signoutto5R9PR3CaMeta?.path ?? "/signout",
    meta: signoutto5R9PR3CaMeta || {},
    alias: signoutto5R9PR3CaMeta?.alias || [],
    redirect: signoutto5R9PR3CaMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedA1MdIRuviEMeta?.name ?? "unauthorized",
    path: unauthorizedA1MdIRuviEMeta?.path ?? "/unauthorized",
    meta: unauthorizedA1MdIRuviEMeta || {},
    alias: unauthorizedA1MdIRuviEMeta?.alias || [],
    redirect: unauthorizedA1MdIRuviEMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/course/pages/unauthorized.vue").then(m => m.default || m)
  }
]