<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <hippo class="mx-auto h-10 scale-75" />
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Page not found
      </h1>
      <p class="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn't find the page you're looking for.
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <nuxt-link
          to="/"
          class="text-md bg-primary flex w-full items-center justify-center rounded-md p-3 font-light text-white">
          Back to home
        </nuxt-link>
        <a
          href="mailto:support@teachmehipaa.com"
          class="text-md flex w-full items-center justify-center rounded-md p-2 font-light text-gray-900"
          >Contact support <span class="pl-2" aria-hidden="true">&rarr;</span></a
        >
      </div>
    </div>
  </main>
</template>
<script setup>
import hippo from "~/assets/images/Hippo.svg";
useHead({
  bodyAttrs: {
    class: "h-full",
  },
  htmlAttrs: {
    class: "h-full",
  },
  title: "Page not found",
});
</script>
