import { defineStore } from "pinia";
import { decodeSqId } from "~/utils/id";

export const useAssessmentStore = defineStore("assessment", {
  state: () => ({
    isLoading: false,
    questions: {
      0: {
        id: 0,
        selected: null,
      },
      1: {
        id: 1,
        selected: null,
      },

      2: {
        id: 2,
        selected: null,
      },
      3: {
        id: 3,
        selected: null,
      },
      4: {
        id: 4,
        selected: null,
      },
      5: {
        id: 5,
        selected: null,
      },
      6: {
        id: 6,
        selected: null,
      },
      7: {
        id: 7,
        selected: null,
      },
      8: {
        id: 8,
        selected: null,
      },
      9: {
        id: 9,
        selected: null,
      },
      10: {
        id: 10,
        selected: null,
      },
      11: {
        id: 11,
        selected: null,
      },
      12: {
        id: 12,
        selected: null,
      },
      13: {
        id: 13,
        selected: null,
      },

      14: {
        id: 14,
        selected: null,
      },

      15: {
        id: 15,
        selected: null,
      },

      16: {
        id: 16,
        selected: null,
      },

      17: {
        id: 17,
        selected: null,
      },
      18: {
        id: 18,
        selected: null,
      },
      19: {
        id: 19,
        selected: null,
      },
    },
  }),
  getters: {
    getSelectedById: (state) => {
      return (questionId) => {
        return state.questions[decodeSqId(questionId)].selected;
      };
    },
    getById: (state) => {
      return (questionId) => {
        return state.questions[decodeSqId(questionId)];
      };
    },
    isLast: (state) => {
      return (shortId) => {
        const lastId = Object.values(state.questions).length - 1;
        return lastId === decodeSqId(shortId);
      };
    },

    isFirst: () => {
      return (shortId) => {
        const id = decodeSqId(shortId);
        return id === 0;
      };
    },
  },

  actions: {
    reset: () => this.questions.map((obj) => ({ ...obj, selected: null })),
    submitAnswer(questionId, choice) {
      const id = decodeSqId(questionId);
      this.questions[id].selected = choice;
    },
    score() {
      return new Promise((resolve) => {
        return resolve(
          this.questions
            .map(({ selected, answer }) => (selected === answer ? 1 : 0))
            .reduce((acc, a) => acc + a, 0) / this.questions.length,
        );
      });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "assessment",
        storage: persistedState.localStorage,
      },
    ],
  },
});
