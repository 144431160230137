import { defineFormKitConfig } from "@formkit/vue";
import { createProPlugin, inputs } from "@formkit/pro";
import { createAutoAnimatePlugin, createFloatingLabelsPlugin } from "@formkit/addons";
import "@formkit/addons/css/floatingLabels";

export default defineFormKitConfig({
  plugins: [
    createProPlugin("fk-8d60e9fa024", inputs),
    createFloatingLabelsPlugin(),
    createAutoAnimatePlugin(
      {
        duration: 250,
        easing: "ease-in-out",
        delay: 0,
      },
      {
        global: ["outer", "inner"],
        form: ["form"],
        repeater: ["items"],
      },
    ),
  ],
});
